import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import Home from './App/Pages/Home';
// import Blank from './App/Pages/Blank';

const LazyHome = React.lazy(() => import('./App/Pages/Home'));
const LazySales = React.lazy(() => import('./App/Pages/Sales'));
const LazyLeads = React.lazy(() => import('./App/Pages/Leads'));
const LazyAppointment = React.lazy(() => import('./App/Pages/Appointment'));
const LazySass = React.lazy(() => import('./App/Pages/Sass'));
const LazyPremium = React.lazy(() => import('./App/Pages/Premium'));
const LazySocial = React.lazy(() => import('./App/Pages/Social'));
const LazyPricing = React.lazy(() => import('./App/Pages/Pricing'));
const LazyFaq = React.lazy(() => import('./App/Pages/Faq'));
const LazyLegal = React.lazy(() => import('./App/Pages/Legal'));
const LazySuccess = React.lazy(() => import('./App/Pages/Success'));

const Loader = () => {
    return 'Loading...';
}

export default props => (
    <Switch>
        {/* <Route exact path='/sales'>
            <React.Suspense fallback={<Loader />}>
                <LazySales />
            </React.Suspense>
        </Route>
        <Route exact path='/leads'>
            <React.Suspense fallback={<Loader />}>
                <LazyLeads />
            </React.Suspense>
        </Route>
        <Route exact path='/appointment'>
            <React.Suspense fallback={<Loader />}>
                <LazyAppointment />
            </React.Suspense>
        </Route>
        <Route exact path='/sass'>
            <React.Suspense fallback={<Loader />}>
                <LazySass />
            </React.Suspense>
        </Route>
        <Route exact path='/premium'>
            <React.Suspense fallback={<Loader />}>
                <LazyPremium />
            </React.Suspense>
        </Route>
        <Route exact path='/social'>
            <React.Suspense fallback={<Loader />}>
                <LazySocial />
            </React.Suspense>
        </Route>
        <Route exact path='/pricing'>
            <React.Suspense fallback={<Loader />}>
                <LazyPricing />
            </React.Suspense>
        </Route>
        <Route exact path='/faq'>
            <React.Suspense fallback={<Loader />}>
                <LazyFaq />
            </React.Suspense>
        </Route>
        <Route exact path='/legal'>
            <React.Suspense fallback={<Loader />}>
                <LazyLegal />
            </React.Suspense>
        </Route>
        <Route exact path='/terms_and_condition'>
            <React.Suspense fallback={<Loader />}>
                <LazyLegal />
            </React.Suspense>
        </Route>
        <Route exact path='/privacy_policy'>
            <React.Suspense fallback={<Loader />}>
                <LazyLegal />
            </React.Suspense>
        </Route>
        <Route exact path='/refund_policy'>
            <React.Suspense fallback={<Loader />}>
                <LazyLegal />
            </React.Suspense>
        </Route>
        <Route exact path='/disclaimers'>
            <React.Suspense fallback={<Loader />}>
                <LazyLegal />
            </React.Suspense>
        </Route>
        <Route exact path='/cookie_policy'>
            <React.Suspense fallback={<Loader />}>
                <LazyLegal />
            </React.Suspense>
        </Route>
        <Route exact path='/client_agreement'>
            <React.Suspense fallback={<Loader />}>
                <LazyLegal />
            </React.Suspense>
        </Route> */}
        <Route path='/success'>
            <React.Suspense fallback={<Loader />}>
                <LazySuccess />
            </React.Suspense>
        </Route>
        <Route path='/'>
            <React.Suspense fallback={<Loader />}>
                <LazyPremium />
            </React.Suspense>
        </Route>
        {/* <Route exact path='/blog' component={Blank} />*/}
    </Switch>
);